.repository-contributor-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__person {
        display: flex;
        align-items: center;
        min-width: 25%;
        flex: none;
        margin-right: 2rem;
        white-space: nowrap;
        overflow: hidden;
    }
    &__commits {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        min-width: 20rem;
        white-space: nowrap;
    }
    &__count {
        flex: none;
        min-width: 5rem;
        text-align: right;
    }
    &__commit {
        flex: 1 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 2rem;
    }

    .badge {
        cursor: default;
    }
}
