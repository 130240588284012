.saved-search-form {
    &__checkbox {
        margin-right: 0.25rem;
    }
    &__label {
        font-weight: bold;
    }

    &__submit-button {
        margin-bottom: 1rem;
    }

    // Hide icon in alert
    &__code-monitoring-alert {
        &::before,
        &::after {
            display: none;
        }
    }
}
