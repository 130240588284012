.diff-stat {
    display: inline-flex;
    align-items: center;

    &__squares {
        display: flex;
        flex-wrap: nowrap;
    }

    &__square {
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        margin-left: 0.125rem;
    }

    &__empty {
        background-color: var(--text-muted);
    }
}
