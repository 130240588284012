.tooltip {
    // Allows line breaks in tooltips
    white-space: pre-wrap;
    animation: 0.25s fade-in;
    pointer-events: none;
    line-height: 1rem;

    > .arrow::before {
        height: 0.8rem; // Fix bug in Firefox where the arrow won't inherit the parent's height
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
