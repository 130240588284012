@import '../../search/input/SearchButton';
@import './TreeEntriesSection';

.tree-page {
    // For the other sub-pages in the RepoArea, we use a child selector to add the
    // border and radius to the sub-page containers. Since we use the Container
    // component here, we already have a pair of borders and had duplicate borders
    // without it. This should be removed once we can get rid of that child selector.
    border: none !important;
    border-radius: 0 !important;
    height: 100%;

    &__container {
        overflow-y: auto;
        height: 100%;
        flex: 1;
        background-color: var(--code-bg);
    }

    &__title {
        display: flex;
        align-items: center;
        margin-left: -0.25rem; // icon flush with left
    }

    &__section {
        width: 100%;

        max-width: map-get($grid-breakpoints, xl);

        &-search {
            display: flex;
            .search-help {
                margin-left: 0.25rem;
                // stylelint-disable-next-line declaration-property-unit-whitelist
                margin-top: -2px;
            }
        }
    }

    .batch-change-badge {
        top: 0;
    }

    .git-commit-node {
        padding-left: 0;
        padding-right: 0;
        .git-commit-node__message-subject {
            opacity: 0.9;
        }
        .btn {
            opacity: 0.85;
        }
    }
}
