$default-icon-colors: $oc-grape-7, $oc-violet-7, $oc-cyan-9, $oc-indigo-7, $oc-pink-8;

// Add this class to the lowest common ancestor of <ActionItemsBar> and <ActionItemsToggle>
.action-items {
    --action-item-width: 2.5rem;
    --action-item-container-width: 2.5625rem; // 2.5rem + 1px

    &__bar {
        flex: 0 0 auto;
        width: var(--action-item-width);
        background-color: var(--body-bg);
        list-style: none;

        &--collapsed {
            width: 0.5rem;
        }
    }

    &__toggle-container {
        width: var(--action-item-width);
        background-color: var(--color-bg-2);
        background-color: var(--body-bg);

        &--open {
            margin-bottom: -0.0625rem;
        }
    }

    // Used to visually separate action items bar sections.
    &__divider-horizontal {
        height: 0.0625rem;
        width: 1.25rem;
        background-color: var(--border-color);
        left: 0.625rem;
    }

    // Used to visually separate action items bar toggle from repo header actions.
    &__divider-vertical {
        height: 1.25rem;
        width: 0.0625rem;
        top: 0.75rem;
        align-self: center;

        border-radius: 2px;
        background-color: var(--border-color);
    }

    &__list {
        overflow-y: auto;

        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__list-item {
        user-select: none;
        &:first-of-type {
            margin-top: 0.375rem;
        }
        &:last-of-type {
            margin-bottom: 0.375rem;
        }
    }

    &__action {
        width: 2rem;
        height: 2rem;
        margin-left: 0.25rem;
        border-radius: 0.1875rem;

        &:hover {
            background-color: var(--color-bg-2);
        }

        &--toggle {
            height: auto;
            padding: 0.25rem;
        }

        &--pressed {
            color: var(--body-color);
            background-color: var(--color-bg-3);

            // Override existing hover styles
            &:hover {
                background-color: var(--color-bg-3);
            }
        }

        &--inactive {
            cursor: default;
            filter: saturate(0%);
            opacity: 0.7;
        }

        // Default icon generated for extensions with no iconURL
        &--no-icon {
            &::after {
                color: var(--white);
                // Center letter
                display: flex;
                align-items: center;
                justify-content: center;
                height: 1rem;
                width: 1rem;
                font-size: (10 / 16) + rem;
                content: attr(data-content);
                border-radius: (2 / 16) + rem;
            }

            &-inactive {
                &::after {
                    background-color: var(--color-bg-3) !important;
                    color: var(--text-muted) !important;
                }
            }
        }
    }

    &__icon {
        height: 1rem !important;
        width: 1rem !important;

        // Default icon background color
        @for $i from 1 through length($default-icon-colors) {
            &-#{$i} {
                &::after {
                    background-color: nth($default-icon-colors, $i);
                }
            }
        }
    }

    // e.g. "close extensions panel", "add extensions"
    &__aux-icon {
        color: var(--icon-color);
    }

    &__scroll {
        width: var(--action-item-width);

        &:hover {
            background-color: var(--color-bg-2);
        }
    }
}
