.user-settings-repos {
    --gradient: linear-gradient(to right, var(--color-bg-2) 4%, var(--color-bg-1) 25%, var(--color-bg-2) 36%);
    --animation: shimmer 2s infinite linear;
    margin-bottom: 5rem;

    &__gitlab {
        fill: #ff5900 !important;
    }
    &__github {
        fill: var(--body-color) !important;
    }
    &__check {
        fill: var(--color-bg-3) !important;
    }

    &__shimmer {
        background: var(--gradient);
        animation: var(--animation);
        background-size: 1000px 100%;
        border-radius: 5px;
    }

    &__shimmer-circle {
        width: 1rem;
        height: 1rem;
        background-image: var(--gradient);
        animation: var(--animation);
        background-size: 1000px;
        border-radius: 50%;
    }

    &__repositorynode {
        border-color: var(--border-color-2) !important;

        &:hover {
            background-color: var(--color-bg-1);
        }
    }

    &__container {
        border-color: var(--border-color-2) !important;
        border: 0;
        padding: 0;
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
        + .user-settings-repos__container {
            border-top: 1px solid var(--border-color);
            padding-top: 1.5rem;
        }
    }

    &__link:hover {
        text-decoration: none;
    }

    &__filter-input {
        max-width: 30% !important;
    }

    &__text-light {
        font-weight: 400;
    }

    &__text-disabled {
        color: var(--text-disabled);
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}
