.codeintel-indexes {
    &__grid {
        display: grid;
        grid-template-columns: [info] minmax(auto, 1fr) [state] min-content [caret] min-content [end];
        row-gap: 1rem;
        column-gap: 1rem;
        align-items: center;
        margin-bottom: 1rem;
        @media (--sm-breakpoint-down) {
            row-gap: 0.5rem;
            column-gap: 0.5rem;
        }
    }
}

.codeintel-index-node {
    &__separator {
        // Make it full width in the current row.
        grid-column: 1 / -1;
        border-top: 1px solid var(--border-color-2);
        @media (--xs-breakpoint-down) {
            margin-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &__state,
    &__information {
        @media (--xs-breakpoint-down) {
            grid-column: 1 / -1;
        }
    }
}
