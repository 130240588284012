.repo-revision-sidebar-symbols {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-height: 0;
    padding-top: 0.5rem;

    > .filtered-connection {
        &__form {
            padding-bottom: 0.75rem;
            > .form-control {
                height: 1.75rem;
                font-size: 0.75rem;
                padding: 0.5rem;
            }
        }

        &__summary-container {
            border-top: none !important;
            border-bottom: 1px solid var(--border-color) !important;
            padding: 0 0.25rem 0.5rem 0.25rem !important;
            min-height: 0 !important;
        }
    }

    &-node {
        font-size: 0.875rem;
        &__link {
            display: block;
            padding: 0.25rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $secondary;

            &--active,
            &:hover {
                background-color: var(--color-bg-3);
                text-decoration: none;
                color: var(--body-color);
                border-radius: var(--border-radius);
            }
        }

        &__name {
            color: var(--body-color);
        }

        &__container-name {
            margin-left: 0.25rem;
        }

        &__path {
            &::before {
                content: ' – ';
            }
        }

        &__container-name,
        &__path {
            .theme-dark & {
                color: #566e9f;
            }
        }
    }
}
