.documentation-welcome-alert {
    /* By default, dismissible alerts are flex containers that position the
    button at the end of the "row". Since we don't want to interfere with the
    normal card styling, we override this to make the alert elements as
    transparent as possible, and then position the close button within the card
    using absolute positioning. */
    display: block;
    position: relative;
    margin: 0;
    padding: 0;

    > div {
        display: block;
        height: 100%;
    }

    > button {
        position: absolute;
        top: 1rem;
        right: 1.5rem;
    }
}
