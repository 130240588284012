.documentation-icon {
    $documentation-tags: (
        'private': $oc-gray-list,
        'deprecated': $oc-red-list,
        'test': $oc-yellow-list,
        'benchmark': $oc-orange-list,
        'example': $oc-green-list,
        'license': $oc-violet-list,
        'owner': $oc-blue-list,
        // Documentation tags derived from SymbolKind
        'array': $oc-red-list,
        'boolean': $oc-red-list,
        'class': $oc-orange-list,
        'constant': $oc-indigo-list,
        'constructor': $oc-violet-list,
        'enum': $oc-blue-list,
        'enumMember': $oc-blue-list,
        'event': $oc-red-list,
        'field': $oc-blue-list,
        'file': $oc-gray-list,
        'function': $oc-violet-list,
        'interface': $oc-green-list,
        'key': $oc-yellow-list,
        'method': $oc-violet-list,
        'module': $oc-grape-list,
        'namespace': $oc-grape-list,
        'null': $oc-red-list,
        'number': $oc-violet-list,
        'object': $oc-orange-list,
        'operator': $oc-gray-list,
        'package': $oc-yellow-list,
        'property': $oc-gray-list,
        'string': $oc-orange-list,
        'struct': $oc-orange-list,
        'typeParameter': $oc-blue-list,
        'variable': $oc-blue-list,
    );

    // Default for unknown tags
    color: $oc-gray-5;

    @each $tag, $color-list in $documentation-tags {
        &--tag-#{$tag} {
            color: map-get($color-list, '5');
        }
    }
}
