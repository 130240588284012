@import 'wildcard/src/global-styles/breakpoints';

.search-context-dropdown {
    &__button {
        color: var(--search-query-text-color);
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        padding: 0.125rem 0.25rem;
        border-radius: 0.25rem;
        border: 2px solid transparent;
        box-sizing: border-box;
        transition: none;

        @media (--xs-breakpoint-down) {
            border: 1px solid var(--input-border-color);
            margin-left: 0;
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: var(--search-query-text-color);
        }

        &:hover,
        &:focus {
            background-color: var(--color-bg-2);
            border-color: var(--color-bg-2);
        }

        &:focus {
            border-color: var(--border-active-color);
            box-shadow: none;
        }

        &:active,
        &--open,
        &--open:hover,
        &--open:focus {
            background-color: var(--color-bg-2);
        }

        &:disabled,
        &:hover:disabled,
        &:active:disabled,
        &:focus:disabled {
            background-color: var(--color-bg-3);
            color: var(--text-muted);
            border-color: transparent;
            opacity: 1;
            pointer-events: auto; // Required to show tooltip on hover

            .theme-light & {
                background-color: var(--color-bg-2);
            }

            .search-filter-keyword,
            .search-keyword {
                color: var(--text-muted);
            }
        }

        &::after {
            // Hide chevron usually shown by dropdown buttons
            display: none;
        }

        &-content {
            display: block;
            font-size: 0.75rem;
            line-height: 1rem;
        }
    }

    &__menu {
        @media (--xs-breakpoint-down) {
            max-width: 85%;
            transform: none;
        }
    }
}
