.documentation-node {
    margin-bottom: 3rem;
    hr {
        border-width: 1px;
    }
    &__heading {
        position: relative;
        left: -1.5rem;
        &:hover {
            .documentation-node__heading-anchor-link {
                visibility: visible;
            }
        }
        &-anchor-link {
            width: 1rem;
            margin-right: 0.5rem;
            visibility: hidden;

            // We want consistent anchor size links, regardless of heading size.
            // stylelint-disable-next-line declaration-property-unit-whitelist
            font-size: 14px;
        }
    }
    &__pill {
        font-size: 0.75rem;
        border-radius: 12px;
        border: 1px solid var(--border-color-2);
        padding-top: 0.15rem;
        padding-bottom: 0.15rem;
    }
    &__pill-icon {
        color: var(--gray-06);
    }
    &__pill-divider {
        height: 0.75rem;
        background: var(--border-color-2);

        // stylelint-disable-next-line declaration-property-unit-whitelist
        width: 1px;
    }

    // TODO(slimsag): make this our global Markdown style
    .markdown pre {
        border: 1px solid var(--border-color);
    }
}
