@import './RepositoriesPopover';

.repo-header {
    flex: none;
    padding: 0;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    &--alert {
        border-width: 1px 0;
    }

    &__action-list-item {
        // Have a small gap between buttons so they are visually distinct when pressed
        // stylelint-disable-next-line declaration-property-unit-whitelist
        margin-left: 1px;
    }

    &__file-action {
        display: block;
        text-align: left;

        span {
            color: var(--body-color);
            margin-left: 0.5rem;
        }
    }

    &__action {
        margin-right: 0.625rem;
        padding: 0.25rem;
    }

    .navbar-nav {
        white-space: nowrap;

        .nav-item {
            display: flex;
            align-items: stretch;

            .nav-link {
                user-select: none;
            }
        }
    }

    &__alert {
        margin: 0 0.25rem;
        padding: 0.125rem 0.25rem;
        cursor: default;
        user-select: none;
    }

    &__icon-chevron {
        opacity: 0.6;
        margin: auto;
    }

    .copy-path-action {
        opacity: 0;
    }
    &:hover,
    &:focus-within {
        .copy-path-action {
            opacity: 1;
        }
    }

    &__spacer {
        flex: 1 1 0;
    }
}
