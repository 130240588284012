.registry-extension-overview-page {
    &__readme {
        flex: 1;
        min-width: 20rem;
    }
    &__sidebar {
        width: 16rem;
    }
    &__sidebar-section {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-top: 1px solid var(--border-color-2);
    }
    &__tag {
        max-width: 24ch;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__icon {
        display: block;
        width: 4.5rem;
        height: 4.5rem;
        object-fit: contain;
    }
    &__sourcegraph-icon {
        margin-left: calc(0.5rem - 2px); /* stylelint-disable-line */
        height: 1.5rem;
        width: 1.5rem;
    }
}
