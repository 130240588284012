.invite-form {
    &__container {
        margin-bottom: 1rem;
        width: fit-content;
    }

    &__status {
        padding-top: 0.5rem;
        overflow: hidden;
    }

    &__alert {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.invited-notification {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__message {
        flex: 1;
    }
}

.user-node {
    &__container {
        border: 0;
        padding: 0.5rem 0;
        + .user-node__container {
            border-top: 1px solid var(--border-color);
        }
    }
}
