.result-container {
    margin-right: 1rem;
    &:last-child {
        border-bottom-width: 1px;
    }
    &:not(:last-of-type) {
        margin-bottom: 0.5rem;
    }

    &__header {
        padding: 0.5rem 0;
        background-color: transparent;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &-title {
            flex: 1 1 auto;
            overflow: hidden;
            display: flex;
        }

        &-divider {
            border-right: 1px solid var(--border-color-2);
            height: 1rem;
            margin: 0 0.25rem;
        }

        p {
            margin-bottom: 0;
        }

        &:not(:only-of-type) {
            border-bottom: none;
        }

        &-description {
            line-height: (14/11);
        }
    }

    &__toggle-matches-container {
        display: flex;
        flex-shrink: 0;
    }
}
