.repository-commit-page {
    width: 100%;
    position: relative; // For HoverOverlay positioning

    .git-commit-node {
        padding: 0;
    }
    &__card {
        padding-top: 0;
    }
}
