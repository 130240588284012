@keyframes gradient {
    0% {
        background-position-x: 100%;
    }
    100% {
        background-position-x: -50%;
    }
}

.streaming-progress {
    flex-grow: 1;
    &__count {
        color: var(--body-color);
        padding: 0.375rem 0.5rem;
        border-radius: var(--border-radius);

        &--in-progress {
            background-color: var(--color-bg-2);
            color: var(--link-color);
            background-image: linear-gradient(90deg, transparent 25%, $oc-blue-6 25%, $oc-blue-6 40%, transparent 40%);
            background-size: 200% 1px;
            background-repeat: no-repeat;
            background-position-y: bottom;
            background-position-x: 100%;
            animation: gradient 3s linear infinite;
        }
    }

    &__skipped {
        &-popover {
            width: 20rem;
            padding: 0;
        }
    }
}

.streaming-skipped-item {
    &__button {
        &:disabled {
            opacity: 1;
            color: var(--body-color);
        }
    }

    &__message {
        $border-size: 2px;

        border-left: $border-size solid var(--primary);

        // Border should appear directly under the width of the icon
        // Halfway past the icon, minus half the border size
        margin-left: calc(#{$icon-inline-size} / 2 - #{$border-size} / 2 + 0.75rem);
        // Halfway past the icon, minus half the border size, plus the icon's right margin
        padding-left: calc(#{$icon-inline-size} / 2 - #{$border-size} / 2 + 0.5rem);

        margin-right: 0.75rem;

        &.markdown {
            code {
                // Force line breaks on markdown `code` elements
                white-space: normal;
                word-break: break-all;
            }

            ul {
                padding-left: 1rem;
            }
        }
    }

    &__bottom-border-spacer {
        border-bottom: 1px solid var(--border-color-2);
        margin-left: 0.75rem;
        margin-right: 0.75rem;
    }

    &__chevron {
        fill: currentColor !important;
    }

    &--warn {
        .streaming-skipped-item__message {
            border-left-color: var(--danger);
        }
    }
}
