@import '../tree/Tree';
@import './blob/BlobPage';
@import './tree/TreePage';
@import './GitReference';
@import '../marketing/Toast';
@import './RepoRevisionSidebar';
@import './RevisionsPopover/RevisionsPopover';
@import './docs/RepositoryDocumentationPage';
@import './commits/RepositoryCommitsPage';

.repo-revision-container {
    flex: 1 1 auto;

    display: flex;
    min-height: 0;

    position: relative; // for sidebar toggle

    &__sidebar {
        background: var(--color-bg-2);

        &-toggle {
            background: var(--color-bg-2);
            color: var(--link-color);

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1; // overlay the content when sidebar is closed
        }

        &-resizable {
            max-width: 60%;
            min-width: 8rem;
            &--open .tree {
                width: 16rem;
            }
        }
    }

    &__content {
        isolation: isolate;
        flex: 1 1 auto;

        display: flex;
        flex-direction: column;
        min-height: 0;
        min-width: 0;

        background-color: var(--body-bg);

        // Add border to repo revision container content
        // but enable variable margin-bottom (by setting it on child div)
        // without having to repeat border styles.
        > div:first-of-type {
            border: 1px solid var(--border-color);
            border-top-left-radius: 0.1875rem;
            border-top-right-radius: 0.1875rem;
            border-bottom: none;
        }
    }

    &__breadcrumb-icon {
        margin-left: 0.125rem;
        margin-right: -0.25rem;
    }

    &__divider {
        margin-left: 0.375rem;
        margin-right: 0.375rem;
    }
}
