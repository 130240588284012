.sidebar {
    isolation: isolate;
    width: 12rem;
    display: flex;
    flex-direction: column;
    &__chevron {
        color: var(--icon-color);
    }

    &__link {
        &--inactive {
            &:hover {
                background-color: var(--color-bg-2);
            }
        }
    }
}
