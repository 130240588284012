.repository-documentation-sidebar {
    &-scroller {
        height: 100%;
        &:last-child {
            padding-bottom: 3rem;
        }
    }
    &__show-more-button > button {
        :focus {
            box-shadow: none;
        }
        > svg {
            display: none;
        }
    }
}
