$compact-summary-padding: 0.5rem 0.75rem;
$compact-summary-divider: solid 1px var(--border-color-2);
$compact-summary-min-height: 2.75rem;

.filtered-connection-filter-control {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.filtered-connection {
    &__nodes {
        list-style-type: none;
        padding: 0;
    }

    &--noncompact &__form {
        margin-bottom: 0.5rem;
    }

    &--compact &__nodes,
    &--compact &__summary {
        margin-bottom: 0;
    }
    &--compact &__filter {
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    &--compact &__loader {
        padding: $compact-summary-padding;
        flex: 0 0;
        // Consistent with the summary to avoid layout shifting
        border-top: $compact-summary-divider;
        min-height: $compact-summary-min-height;
    }
    &--compact &__error {
        border-radius: 0;
    }
    &--compact &__nodes {
        flex: 1 1;
        overflow-y: auto;
    }

    &__summary-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &:empty {
            // Hide if no children
            display: none;
        }
    }
    &--compact &__summary-container {
        padding: $compact-summary-padding;
        border-top: $compact-summary-divider;
        min-height: $compact-summary-min-height;
    }

    &__summary {
        color: var(--text-muted);
    }
    &--compact &__summary {
        flex: 1;
    }

    &--noncompact {
        table {
            width: 100%;
        }
    }

    table {
        td {
            vertical-align: middle;
        }
    }

    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__show-more {
        flex: 0 0 auto;
        margin-left: auto;
    }
    &--noncompact &__show-more {
        margin-bottom: 1rem;
    }
}

.filtered-connection__centered-summary {
    .filtered-connection {
        &__summary-container {
            flex-direction: column;
            justify-content: center;
            margin-top: 1rem;
        }
        &__summary {
            margin-bottom: 0;
        }
        &__show-more {
            margin-left: 0;
            margin-top: 0.25rem;
            margin-bottom: 0;
        }
    }
}
