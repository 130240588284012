.install-browser-extension-alert {
    &__icon {
        height: 1.5rem;
        width: 1.5rem;
    }

    &__close-icon {
        height: 1.5rem;
        width: 1.5rem;
    }
}
