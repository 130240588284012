.search-results-info-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;

    .alert {
        margin-bottom: 0.5rem;
    }

    .mdi-icon {
        margin-right: 0.125rem;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
        height: 100%;
        flex: 1 0 auto;
        text-align: left;
    }

    &__notice {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        padding: 0.375rem 0;
    }

    &__divider {
        height: 1rem;
        border-right: 1px solid var(--border-color);
        margin: 0 0.5rem 0 0;

        &:first-child {
            display: none;
        }
    }

    &__expander {
        display: block;
        flex-grow: 1;
    }

    .nav .nav-item:last-child {
        margin-right: 0 !important;
    }
}
