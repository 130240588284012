@import './VersionContextDropdown';

.global-navbar {
    flex: 0 0 auto;
    max-height: 80%;
    display: flex;
    align-items: center;
    width: 100%;

    &__logo {
        height: 1.5rem;
        width: 1.5rem;
    }

    &__link {
        color: var(--body-color);
        border-radius: var(--border-radius);
    }

    &__sign-up {
        border: 1px solid transparent;
        background-color: var(--brand-secondary);
        color: var(--white);

        &:hover {
            background-color: var(--brand-secondary-3);
            color: var(--white);
        }
    }
}
