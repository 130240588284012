@import './RepositoryDocumentationSidebar';
@import './DocumentationWelcomeAlert';
@import './DocumentationIcon';
@import './DocumentationExamples';
@import './DocumentationIndexNode';
@import './DocumentationNode';

.repository-docs-page {
    width: 100%;
    display: flex;
    overflow: hidden;
    &__container {
        overflow: auto;
        width: 100%;
        &-content {
            width: 60rem;
            padding-left: 2rem;
            padding-bottom: 3rem;
            margin: auto;
            &--sidebar-visible {
                margin: unset;
            }
        }
    }
    a.h1,
    a.h2,
    a.h3,
    a.h4,
    a.h5,
    a.h6 {
        display: inline-block;
        margin-top: 1rem;
    }
}
