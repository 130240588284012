.toggle-container {
    display: flex;
    align-items: center;

    &__separator {
        // stylelint-disable-next-line declaration-property-unit-whitelist
        width: 1px;
        height: 1.125rem;
        margin: 0 0.5rem 0 0.375rem;
        background-color: var(--border-color-2);
    }

    &__copy-query-button {
        color: var(--search-filter-keyword-color) !important;
        padding: 0.125rem;

        .mdi-icon {
            width: 1rem;
            height: 1rem;
        }
    }

    &__toggle {
        display: flex;
        cursor: pointer;
        border-radius: 4px;
        border: 2px solid transparent;

        &:not(:last-child) {
            margin-right: 0.125rem;
        }

        &:hover {
            background-color: var(--color-bg-2);
        }

        &:focus {
            box-shadow: none;
        }

        &:active {
            // stylelint-disable-next-line declaration-property-unit-whitelist
            border: 2px solid var(--primary);
        }

        &.disabled,
        &.disabled:hover {
            background-color: var(--color-bg-3);
            color: var(--text-muted);
            cursor: default;
            opacity: 1;

            .theme-light & {
                background-color: var(--color-bg-2);
            }

            &:active {
                border-color: transparent;
            }
        }

        &--active {
            background-color: var(--primary);
            color: #ffffff !important;

            &:hover {
                background-color: var(--primary);
            }
        }
    }
}
