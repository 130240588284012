@import './FileDecorator.scss';

.tree {
    isolation: isolate;
    flex: 1 1 auto;
    width: 100%;
    white-space: pre;
    line-height: (20/14);
    font-size: 0.875rem;

    user-select: none;

    &__cell {
        padding-left: 0;
        padding-right: 0;
        border-radius: var(--border-radius);
    }

    &__row {
        color: var(--body-color);
        width: 100%;
        &:hover {
            background-color: var(--color-bg-2);
        }

        &--selected {
            background-color: var(--color-bg-3);
            cursor: pointer;

            &:hover {
                background-color: var(--color-bg-3);
            }
        }

        &--active {
            width: 100%;
            background-color: var(--color-bg-3);
            color: var(--body-color);
        }

        &-contents,
        &-label {
            &:hover {
                text-decoration: none;
            }
        }

        .theme-light &--active,
        .theme-light &--selected,
        .theme-light &:hover {
            meter {
                --meter-background: var(--gray-05);
            }
        }
    }

    &__row-alert {
        display: block;
        padding: 0.75rem 1.25rem;
        white-space: pre-wrap;
        margin-bottom: 0;
    }

    &__row-icon {
        color: var(--link-color);
        // stylelint-disable-next-line declaration-property-unit-whitelist
        width: 1.1em;
    }

    &__row-contents {
        display: flex;
        color: inherit;
        align-items: center;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        cursor: pointer;

        &-new {
            justify-content: space-between;
        }

        &-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__row-label {
        margin-left: 0.25rem;
        color: inherit;
    }

    a {
        color: inherit;
    }
}

.tree-layer {
    width: 100%;
    outline: none;
}
