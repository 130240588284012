.monaco-query-input {
    .monaco-editor {
        // Disable stylelint for Monaco modifications
        // stylelint-disable

        .decorationsOverviewRuler {
            display: none;
        }
        .view-overlays {
            .current-line {
                border: none;
            }
        }
        .view-lines {
            border: none;
        }

        .monaco-hover-content {
            padding: 0.25rem;
            .status-bar {
                display: none;
            }
        }
        .minimap {
            // Minimap is disabled but will still be rendered and affect eg. visual tests, force hide to prevent this.
            display: none;
        }

        // Override our :focus-visible style, which gets applied to the textarea
        .inputarea.focus-visible {
            box-shadow: none;
        }

        .suggest-widget .monaco-list .monaco-list-row.focused.string-label > .contents > .main > .right {
            // Show keyboard tip to press Tab to complete
            &::after {
                content: 'Tab';
                display: flex;
                align-items: center;
                border: 1px solid var(--text-muted);
                border-radius: 0.25rem;
                height: 1.25rem;
                margin-top: 0.375rem;
                font-size: 0.65rem;
                line-height: 1;
                padding: 0.25rem 0.375rem;
                color: var(--text-muted);
                opacity: 0.75;
            }

            // Restore old look of "Read more" icon
            > .readMore {
                position: static;
                visibility: visible;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &::before {
                    content: '\ea74'; // Info icon
                    font-size: 1rem;
                }
            }

            // Don't shrink label when hovering since the "Read more" icon is always shown
            &.can-expand-details > .details-label {
                width: auto;
            }
        }
    }

    // Replace default issues icon with filter icon since we use CompletionItemKind.Issue for filter autocomplete
    .suggest-icon.codicon.codicon-issues::before {
        content: '\eaf1';
    }

    // Replace default color icon with repo icon since we use CompletionItemKind.Color for repo autocomplete
    .suggest-icon.codicon.codicon-symbol-color::before {
        content: '\ea63';
    }
}
