.git-ref-node {
    --git-ref-divider-color: var(--border-color-2);
    color: var(--text-muted);

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid var(--git-ref-divider-color);
    &:first-of-type {
        border-top: 1px solid var(--git-ref-divider-color);
    }
}
