$navbar-padding-y: ($spacer / 4);
$navbar-padding-x: ($spacer / 2);
$nav-tabs-border-color: var(--border-color);
$nav-tabs-link-hover-border-color: var(--border-color) var(--border-color) transparent;
$nav-tabs-link-active-color: var(--body-color);
$nav-tabs-link-active-border-color: var(--border-color) var(--border-color) transparent;

@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';

// Ensure that tabs are all the same height, regardless of whether they have an icon. Without this,
// tabs with an icon are 38px and tabs without an icon are 37.5px, which causes tabs without an icon
// to have an undesirable bottom border when active.
.nav-tabs > .nav-item > .nav-link {
    height: 100%;

    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: none;
    border-bottom: 2px solid transparent;
    color: var(--text-muted);
    &:focus-visible {
        border-radius: var(--border-radius);
        // Add this new stacking context in order to show the focus ring without cutting any edge.
        isolation: isolate;
        z-index: 1;
    }
    &:not(.active):hover {
        border-bottom: 2px solid var(--border-color-2);
        margin-top: 0;
        color: var(--body-color);
    }
    // ::after used here for avoids the CLS when the font-weight change (see: https://css-tricks.com/bold-on-hover-without-the-layout-shift/)
    .text-content {
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        &::after {
            content: attr(data-tab-content);
            height: 0;
            text-transform: capitalize;
            visibility: hidden; // a11y: avoid detection for voice over
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-weight: 700;
        }
    }
}

.nav-tabs > .nav-item > .nav-link.active {
    color: var(--body-color);
    font-weight: 700;
    border-bottom: 2px solid var(--brand-secondary);
}

// Bootstrap's nav base class does not include styles for the active state.
.nav-link.active {
    color: var(--link-active-color);
}
