@import 'bootstrap/scss/badge';

// Light theme badge variants
:where(.theme-light) {
    @each $color, $value in $theme-colors {
        .badge-#{$color},
        :where(a).badge-#{$color} {
            @include badge-variant($value);
        }
    }
}

:root {
    --badge-font-size: 0.75rem;
    --badge-font-weight: 500;
    --badge-padding-y: 0.125rem;
    --badge-padding-x: 0.375rem;
    --badge-border-radius: 3px;
}

.badge {
    background-color: var(--subtle-bg);
    color: var(--link-color);
    border: none;
    line-height: 1rem;

    &:focus,
    &.focus {
        outline: 0;
    }
}

.badge-sm {
    --badge-font-size: #{(11/16)}rem;
    --badge-padding-y: 0;
    --badge-padding-x: 0.25rem;
    --badge-border-radius: 2px;
}

a.badge {
    // Badge acts like btn-link, replicate Bootstrap btn transition
    transition: $btn-transition;
}

// Variants
.badge-primary,
.badge-secondary,
.badge-success,
.badge-danger,
.badge-info,
.badge-warning,
.badge-merged,
.badge-outline-secondary {
    background-color: var(--badge-base);
    color: var(--badge-text);
    border: var(--badge-border, none);

    @at-root #{selector-unify('a', &)} {
        background-color: var(--badge-base);
        color: var(--badge-text);

        &:hover,
        &:focus,
        &.focus {
            color: var(--badge-text);
            background-color: var(--badge-dark);
        }

        &:focus,
        &.focus {
            outline: 0;

            @at-root #{selector-append('.theme-light', &)} {
                box-shadow: 0 0 0 0.125rem var(--badge-light);
            }
            @at-root #{selector-append('.theme-dark', &)} {
                box-shadow: 0 0 0 0.125rem var(--badge-dark);
            }
        }
    }
}

.badge-primary {
    --badge-base: var(--primary);
    --badge-light: var(--primary-2);
    --badge-dark: var(--primary-3);
    --badge-text: var(--light-text);
}

.badge-secondary {
    --badge-base: var(--secondary);
    --badge-light: var(--secondary-2);
    --badge-dark: var(--secondary-3);
    --badge-text: var(--body-color);
}

.badge-success {
    --badge-base: var(--success);
    --badge-light: var(--success-2);
    --badge-dark: var(--success-3);
    --badge-text: var(--light-text);
}

.badge-danger {
    --badge-base: var(--danger);
    --badge-light: var(--danger-2);
    --badge-dark: var(--danger-3);
    --badge-text: var(--light-text);
}

.badge-info {
    --badge-base: var(--info);
    --badge-light: var(--info-2);
    --badge-dark: var(--info-3);
    --badge-text: var(--dark-text);
}

.badge-warning {
    --badge-base: var(--warning);
    --badge-light: var(--warning-2);
    --badge-dark: var(--warning-3);
    --badge-text: var(--dark-text);
}

.badge-merged {
    --badge-base: var(--merged);
    --badge-light: var(--merged-2);
    --badge-dark: var(--merged-3);
    --badge-text: var(--light-text);
}

.badge-outline-secondary {
    --badge-base: transparent;
    --badge-light: var(--secondary-2);
    --badge-dark: var(--secondary-3);
    --badge-text: var(--text-muted);
    --badge-border: 1px solid var(--secondary);
}

.theme-light,
.theme-dark {
    // Update secondary text color and focus state for better contrast
    a.badge-secondary,
    a.badge-outline-secondary {
        &:focus,
        &.focus {
            box-shadow: var(--focus-box-shadow);
        }
    }
    a.badge-secondary {
        &:hover,
        &:focus,
        &.focus {
            color: var(--body-color);
        }
    }
    a.badge-outline-secondary {
        &:hover,
        &:focus,
        &.focus {
            background-color: var(--color-bg-1);
        }
    }
}
