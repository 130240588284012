.signin-signup-page {
    width: 100%;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.signin-signup-form {
    max-width: calc(100vw - 2rem);
    background-color: var(--color-bg-1);
    border: 1px solid var(--border-color-2);

    &__input-container {
        position: relative;
    }

    &__icon {
        position: absolute;
        right: calc(0.5rem - 1px);
        top: calc(0.5rem - 1px);
    }
}

.signin-page {
    &__container {
        width: 20rem;
    }
}

.signup-page {
    &__container {
        width: 27.5rem;
    }
}

.post-signup-page {
    margin-top: 7rem;
    margin-bottom: 5rem;
    align-self: flex-start;

    &__loading::after {
        width: 0;
        overflow: hidden;
        content: '...';
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 1500ms infinite;
    }

    @keyframes ellipsis {
        to {
            width: 2rem;
        }
    }

    &__logo {
        height: 1.75rem;
        width: 1.75rem;
    }
    &__logo-link {
        outline: 0;
        margin: 0.25rem 0.5rem;
        display: flex;
        width: fit-content;
        text-decoration: none;
        position: absolute;
    }
    @media screen and (max-width: 50rem) {
        &__logo-link {
            position: relative;
        }
    }
    &__link-disabled {
        color: var(--hl-gray-5);
    }
    &__container {
        width: 37.25rem;
    }
}
