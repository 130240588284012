.extensions-list {
    &__cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr));
        grid-auto-rows: minmax(16.25rem, auto);
        gap: $spacer * 0.75;

        &--featured {
            grid-auto-rows: minmax(21.25rem, auto);
        }
    }

    &__category {
        margin-top: 2rem;
        font-size: 1.5rem !important;
    }

    &__featured-section {
        position: relative;
        // 2rem gap before next section, 1rem extra for visual effect of ::before pseudo-element.
        margin-bottom: 3rem;
        // Same as above but for 2rem gap between featured section and search bar.
        margin-top: 3rem;

        // Use pseudo-element to make background "bleed out" of the container.
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            inset: -1rem;
            background-color: var(--color-bg-1);
            border: 1px solid var(--border-color-2);
            border-radius: var(--border-radius);
            padding: 0.5rem;
        }
    }
}
