.user-settings-emails-page {
    .form-inline input:not(.is-invalid),
    .form-inline input:not(.is-valid) {
        padding-right: 2rem;
    }
    &__dot {
        color: var(--border-color);
    }
    &__list-item {
        border: 0;
        padding: 1rem 0;
        + .user-settings-emails-page__list-item {
            border-top: 1px solid var(--border-color);
        }
    }
    &__email-form {
        padding-top: 0.5rem;
    }
}
