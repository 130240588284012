.code-monitoring-node {
    &__toggle-wrapper {
        // stylelint-disable-next-line declaration-property-unit-whitelist
        margin-top: 1px; // For visual alignment with the edit button
    }

    &__edit-button {
        padding: 0;
    }

    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }
    + .code-monitoring-node {
        padding-top: 1rem;
        border-top: 1px solid var(--border-color);
    }
}
