.shepherd-arrow::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    // stylelint-disable-next-line declaration-property-unit-whitelist
    border: 8px solid #000000;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: var(--color-bg-3);
    border-left-color: transparent;
    transform-origin: 0 0;
}

.shepherd-element[data-popper-placement^='bottom'] .shepherd-arrow::after {
    top: -40px;
}

.shepherd-element[data-popper-placement^='top'] .shepherd-arrow::after,
.shepherd-element.shepherd-pinned-top .shepherd-arrow::after {
    transform: rotate(180deg);
    top: 90px;
}

.shepherd-modal-overlay-container {
    display: none;
}

.shepherd-element[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
}
