@import 'open-color/open-color.scss'; // Sass variables
@import '~open-color/open-color.css'; // CSS variables

// Tell Bootstrap to use our colors.
$blue: #0b70db;
$indigo: #0864c6;
$purple: #a305e1;
$pink: #d68cf3;
$red: $oc-red-9;
$orange: $oc-orange-7;
$yellow: $oc-yellow-7;
$green: $oc-green-7;
$teal: $oc-teal-7;
$cyan: #72dbe8;
$body-color: var(--body-color);
$body-bg: var(--body-bg);
$text-muted: var(--text-muted);

// Exception that's used in [data-reach-dialog-overlay] background.
$gray-04: #dbe2f0;
// Exception that's used in $custom-select-indicator as SVG icon fill color.
$gray-06: #798baf;
// Exception that's used in $theme-colors and in --box-shadow variable.
$gray-08: #343a4d;

// Core semantic base colors.
$primary: $blue;
$secondary: $gray-08;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$merged: $purple;

// Color for the <mark> element and highlighted/hovered tokens.
$mark-bg-dark: rgba(217, 72, 15, 0.5);
$mark-bg-light: rgba(255, 192, 120, 0.5);

// Added to bg- and text- utilities because it is commonly needed in our app.
$theme-colors: (
    'merged': $merged,
);

:root {
    // Gray (blueish) root palette.
    --white: #ffffff;
    --gray-01: #f9fafb;
    --gray-02: #f0f1f2;
    --gray-03: #e6ebf2;
    --gray-04: #{$gray-04};
    --gray-05: #a6b6d9;
    --gray-06: #{$gray-06};
    --gray-07: #5e6e8c;
    --gray-08: #{$gray-08};
    --gray-09: #262b38;
    --gray-10: #1d212f;
    --gray-11: #181b26;
    --gray-12: #14171f;
    --black: #0f111a;

    // Other root palette colors.
    --blue: #{$blue};
    --indigo: #{$indigo};
    --purple: #{$purple};
    --pink: #{$pink};
    --red: #{$red};
    --orange: #{$orange};
    --yellow: #{$yellow};
    --green: #{$green};
    --teal: #{$teal};
    --cyan: #{$cyan};

    // Logo colors
    --logo-orange: #ff5543;
    --logo-blue: #00cbec;
    --logo-purple: #a112ff;

    // Semantic colors.
    --primary: var(--blue);
    --primary-3: var(--indigo);
    --brand-secondary: var(--purple);
    --brand-secondary-2: var(--pink);
    --brand-secondary-3: #8900bf;
    --success: var(--green);
    --success-2: #afe0b8;
    --info: var(--cyan);
    --warning: var(--yellow);
    --warning-2: #fbd999;
    --danger: var(--red);
    --danger-2: #e9aaaa;
    --merged: #7048e8;
    --merged-2: #c6b6f6;
    --merged-3: #6b47d6;
    --light-text: var(--white);
    --dark-text: var(--gray-08);
    --line-number-color: var(--gray-06);
    --header-icon-color: var(--gray-05);
    --tooltip-bg: var(--gray-08);
}

.theme-light {
    // Affects default browser styles
    color-scheme: light;

    --primary-2: #a3d0ff;
    --primary-4: #e1f0ff;
    --secondary: var(--gray-04);
    --secondary-2: #eef1f7;
    --secondary-3: #c6cfe1;
    --secondary-4: #f1f4f9;
    --success-3: #319e44;
    --success-4: #ebfaee;
    --info-2: #a8dbe2;
    --info-3: #0bb3ca;
    --info-4: #eafafc;
    --warning-3: #e09200;
    --warning-4: #fff7e1;
    --danger-3: #b52626;
    --danger-4: #fbeaea;
    --body-color: var(--gray-08);
    --body-bg: var(--gray-01);
    --color-bg-1: var(--white);
    --color-bg-2: var(--gray-03);
    --color-bg-3: var(--gray-04);
    --mark-bg: #f8e688;
    --merged-4: #eee9fd;
    --text-muted: var(--gray-07);
    --text-disabled: var(--gray-05);
    --link-color: var(--primary);
    --link-color-2: #0766cc;
    --link-active-color: #0c7bf0;
    --link-hover-color: #0c7bf0;
    --border-color: var(--gray-03);
    --border-color-2: var(--gray-04);
    --border-active-color: var(--primary);
    --search-query-text-color: var(--gray-12);
    --search-filter-keyword-color: var(--primary);
    --search-keyword-color: var(--purple);
    --infobar-warning-color: var(--oc-red-8);
    --icon-color: var(--gray-06);
    --icon-muted: var(--gray-05);
    --code-bg: var(--white);
    --intel-bg: var(--white);
    --subtle-bg: var(--gray-02);
    --search-input-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
    --code-line-highlight-color: var(--color-bg-2);
    --diff-add-bg: #eeffec;
    --diff-remove-bg: #ffecec;
    --code-selection-bg: var(--gray-03);
    --sourcegraph-logo-text-color: #000000;
}

.theme-dark {
    // Affects default browser styles.
    color-scheme: dark;

    --primary-2: #0f59aa;
    --primary-4: #03284f;
    --secondary: var(--gray-08);
    --secondary-2: #242936;
    --secondary-3: #1f232e;
    --secondary-4: #545967;
    --success-3: #237332;
    --success-4: #054410;
    --info-2: #b8e3e8;
    --info-3: #005766;
    --info-4: #025762;
    --warning-3: #9c6500;
    --warning-4: #3d2904;
    --danger-3: #801b1b;
    --danger-4: #3d0a0a;
    --body-color: var(--gray-04);
    --body-bg: var(--gray-12);
    --color-bg-1: var(--gray-11);
    --color-bg-2: var(--gray-10);
    --color-bg-3: var(--gray-08);
    --mark-bg: #7a341e;
    --merged-4: #1f0a5c;
    --text-muted: var(--gray-05);
    --text-disabled: var(--gray-07);
    --link-color: #4393e7;
    --link-color-2: #70b0f3;
    --link-active-color: #489ffa;
    --link-hover-color: #489ffa;
    --border-color: var(--gray-09);
    --border-color-2: var(--gray-08);
    --border-active-color: #4393e7;
    --search-query-text-color: var(--gray-04);
    --search-filter-keyword-color: #4393e7;
    --search-keyword-color: var(--pink);
    --infobar-warning-color: #f05151;
    --icon-color: var(--gray-05);
    --icon-muted: var(--gray-07);
    --code-bg: var(--gray-10);
    --intel-bg: var(--gray-12);
    --subtle-bg: var(--gray-09);
    --search-input-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
    --code-line-highlight-color: var(--body-bg);
    --diff-add-bg: #224035;
    --diff-remove-bg: #3e1d1d;
    --code-selection-bg: var(--gray-08);
    --sourcegraph-logo-text-color: var(--white);
}

// Additional colors global colors.
.theme-dark,
.theme-light {
    --box-shadow: 0 0.25rem 0.5rem #{rgba($gray-08, 0.07)};
    --focus-box-shadow: 0 0 0 0.125rem var(--primary-2);
    --btn-link-disabled-color: var(--primary-2);
}
