.connection-popover {
    isolation: isolate;
    display: flex;
    flex-direction: column;

    min-width: 25rem;
    max-width: 70vw;

    --popover-item-padding-h: 1rem;
    --popover-item-padding-v: 0.25rem;
    background-color: var(--color-bg-1);

    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        max-height: calc(min(20rem, 70vh));

        .alert {
            word-break: break-word;
        }
    }

    .filtered-connection & {
        &__nodes {
            &:empty {
                display: none;
            }
            border-top: solid 1px var(--border-color-2);
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }

        &__node {
            border-bottom: none;

            &:last-child {
                border-bottom: none;
            }
            &-link {
                display: flex;
                align-items: center;
                padding: var(--popover-item-padding-v) var(--popover-item-padding-h);
                color: var(--text-muted);
                text-decoration: none;
                border: none;

                &:hover,
                &:focus {
                    background-color: var(--primary);
                    color: var(--light-text);
                }

                &--active {
                    font-weight: inherit;
                    background-color: var(--color-bg-3);
                }
            }
        }

        &__input {
            margin: 0.5rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}
