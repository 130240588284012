.user-code-hosts-page {
    &__icon--inside {
        position: absolute;
        right: 0.45rem;
        top: 0.55rem;
    }
    &__icon--dashed {
        color: var(--icon-muted);
    }
    &__modal--plain {
        background-color: var(--theme-bg-plain);
    }
    &__code--inline {
        font-size: 0.75rem;
        line-height: (20/14);
    }

    &__code-host-item {
        border: 0;
        padding: 0;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
        + .user-code-hosts-page__code-host-item {
            border-top: 1px solid var(--border-color);
            padding-top: 1rem;
        }
    }
}
