.status-bar {
    background-color: var(--code-bg);
    height: 2rem;
    // Define `width` and `display` in this class, instead of with utility classes,
    // to allow consumers to override them with utility classes
    width: 100%;
    display: flex;

    &__items {
        overflow-x: auto;
        white-space: nowrap;
        margin: 0;

        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__scroll {
        width: 1.5rem;
        height: 100%;
        padding: 0;
        color: var(--icon-color);

        &:hover {
            background-color: var(--color-bg-2);
        }

        &--disabled {
            visibility: hidden;
            width: 0;
            margin: 0;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-right: 0.25rem;
        }

        &:last-child {
            padding-right: 0.5rem !important;
        }

        &--noop {
            cursor: default;
            color: var(--body-color);
        }
    }

    &__text {
        padding-top: 0.0625rem; // 1px
    }
}
