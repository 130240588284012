.search-context-menu {
    &__header {
        display: flex;
        padding: 0.5rem;
        border: 1px solid transparent;
        border-bottom: 1px solid var(--border-color-2);

        &-input {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            font-size: 0.75rem;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 1rem 0.5rem;
        border-bottom: 1px solid var(--border-color-2);
        color: var(--text-muted);

        &-close {
            color: var(--icon-color);
        }
    }

    &__list {
        width: 31rem;
        max-width: 100%;
        padding: 0.25rem 0;
        max-height: 300px;
        overflow: auto;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 0.25rem 1rem;

        &--error {
            color: var(--danger) !important;
        }

        &-name {
            width: 10rem;
            margin-right: 0.25rem;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-shrink: 0;
        }

        &-description {
            overflow: hidden;
            text-overflow: ellipsis;
            flex-grow: 1;
            font-size: 0.75rem;
        }

        &:not(:active) {
            > .search-context-menu__item-description {
                color: var(--text-muted);
            }
        }

        &--selected {
            background-color: var(--color-bg-3);
        }

        &--highlighted {
            font-weight: 700;
        }

        &:hover,
        &:active,
        &:focus {
            // Override default focus styles
            box-shadow: none;
            outline: none;

            background-color: var(--primary);
            color: var(--light-text);

            > .search-context-menu__item-description {
                color: var(--light-text);
            }
        }

        &:hover,
        &:active,
        &:focus,
        &--selected {
            // Badge-secondary variant for improved contrast when background color changes
            > .search-context-menu__item-default {
                background-color: var(--color-bg-1);
                border-color: var(--color-bg-1);
            }
        }
    }

    &__footer {
        display: flex;
        border-top: 1px solid var(--border-color-2);
        padding: 0.75rem 1rem 0.5rem;

        &-button {
            color: var(--link-color);
            padding: 0;
        }
    }

    &__infinite-scroll-trigger {
        // stylelint-disable-next-line declaration-property-unit-whitelist
        height: 1px;
    }
}
