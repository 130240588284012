.trigger-area {
    &__query-input {
        display: flex;
        align-items: flex-start;

        &-preview-link {
            white-space: nowrap;
            font-size: 0.75rem;
            background-color: var(--color-bg-2);
            border: 1px solid var(--input-border-color);
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;

            &-text {
                display: flex;
                align-items: center;
                line-height: (1/0.75);
                height: 1rem;
            }

            &-icon {
                max-height: 1rem;
                max-width: 1rem;
            }
        }

        &-field {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            font-size: 0.75rem;
            // stylelint-disable-next-line declaration-property-unit-whitelist
            height: calc(1rem + 0.5rem * 2 + 1px * 2); // 1rem for text, 0.5rem vertical padding, 1px border
        }
    }

    &__query-label {
        white-space: pre-wrap;
    }

    &__checklist {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        &-checkbox {
            font-size: 0.65rem;
            margin-right: 0.5rem;
            color: var(--icon-muted);

            &--unchecked {
                // Make unchecked icon smaller so it doesn't look like a radio button.
                // Scale instead of setting font size so that both checked and
                // unchecked icons occupy the same space without having to do margin math.
                transform: scale(0.55);
            }
        }

        &-children {
            &--faded {
                opacity: 0.4;
            }
        }

        &-hint {
            font-size: 0.65rem;
            margin-left: 0.25rem;

            &--faded {
                opacity: 0.4;
            }
        }

        &-tooltip {
            text-align: left;
            max-width: 16rem;
        }
    }
}
