@import './branches/RepositoryBranchesPage';
@import './commit/RepositoryCommitPage';
@import './RepositoryNotFoundPage';
@import './RepoHeader';
@import './RepoRevisionContainer';
@import './compare/RepositoryCompareArea';
@import './stats/RepositoryStatsArea';

.repo-container {
    margin-left: auto;
    margin-right: auto;

    &__repo-change {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}
