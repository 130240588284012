.bar-chart {
    position: relative;

    &__bar--with-link {
        cursor: pointer;
    }

    &__grid {
        line {
            stroke: var(--border-color-2);
            stroke-width: 1;
        }
    }

    // Axes
    &__axis-line {
        stroke: var(--border-color);
        stroke-width: 1;

        &--vertical {
            stroke-width: 0;
        }
    }

    &__axis-tick {
        // small tick line
        line {
            stroke: var(--border-color);
            stroke-width: 1;
        }

        // tick label
        text {
            fill: var(--text-muted);
            font-size: 0.75rem;
            font-weight: 400;
        }

        &--vertical {
            // ticks of vertical y-axis
            line {
                stroke-width: 0;
            }
        }
    }

    &__bar-link:focus &__bar {
        stroke: var(--primary);
        stroke-width: 3;
    }

    /*
      Tooltip element from visx package is adding some inline styles by himself.
      There is no way to override them from css unless !important statement.
    */
    &__tooltip {
        box-shadow: var(--border-color-2) 0 1px 2px !important;
        border: 1px solid var(--border-color);
        color: var(--body-color) !important;
        background: var(--body-bg) !important;
    }
}
