.documentation-index-node {
    &-row {
        position: relative;
        &--shift-left {
            left: -1.25rem;
        }
    }
    &-expand-button {
        color: var(--gray-06);
    }
    &-active-circle {
        color: var(--purple);
    }
}
