@import '../../nav/GlobalNavbar';
@import '../QuickLinks';
@import '../input/MonacoQueryInput';
@import '../input/InfoDropdown';
@import '../input/SearchContextDropdown';
@import '../input/SearchContextMenu';
@import '../input/SearchOnboardingTour';
@import '../panels/HomePanels';
@import '../input/toggles/Toggles';
@import './homepage';

.search-page {
    justify-content: flex-start;
    width: 100%;
    overflow: auto;

    &__logo {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        width: 20rem;
        margin-top: 6rem;
        max-width: 90%;
        // Reserve the image's height to avoid jitter before it loads.
        min-height: 54px;
    }

    &__search-container {
        flex: 1 1 auto;
        margin-top: 2.5rem;
        width: 100%;

        @media (--xl-breakpoint-up) {
            max-width: $max-homepage-container-width;
        }

        position: relative;

        &--with-content-below {
            flex-grow: 0;
            margin-bottom: 5rem;
        }
    }

    &__input-container {
        width: 100%;
        display: flex;
    }

    &__input-sub-container {
        width: 100%;
        display: block;
        margin: 0.5rem 0;
    }
}
