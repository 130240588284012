.search-button {
    &__btn {
        // Left side is flush with QueryInput.
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        display: flex;
        padding: 0.5rem 1rem;
        border: none;

        @media (--xs-breakpoint-down) {
            border-radius: var(--border-radius);
        }
    }
}
