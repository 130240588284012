@import '../../components/ConnectionPopover';

.revisions-popover {
    isolation: isolate;
    width: 35rem;

    &__tabs {
        display: flex;
        justify-content: space-between;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0.25rem;

        &-close {
            color: var(--icon-color);
        }
    }

    &-git-commit-node {
        &__link {
            display: flex;
        }
        &__message {
            flex: 1 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 0.5rem 0;
        }
    }
}
