.file-path-breadcrumbs {
    display: flex;
    min-width: 0;
    overflow: hidden;
    align-items: center;
    white-space: nowrap;

    .part {
        // Ensure focus shadow is not clipped by overflow: hidden
        margin: 0.125rem;
    }

    .part-directory {
        flex: 1 1 1em;
        min-width: 1em;
        max-width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }

    &__separator {
        // IMPORTANT
        //
        // This needs to be margin, not padding, because padding can become
        // spaces when copy-pasted in Chrome, while margin is ignored.
        margin: 0 0.0625rem;
        font-weight: 600;
    }
}
