@import './chart-view-content/ChartViewContent';

.view-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    position: relative;

    // Allow view contents to scroll vertically
    overflow-y: auto;

    &__markdown {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        // Prevent overflow of misbehaving extensions
        overflow-x: hidden;
    }

    &__chart {
        // Fix for right chart size calculation in Safari browser
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .view-content-alert-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &:hover,
    &:focus-within {
        .view-content-alert-overlay {
            display: none;
        }
    }
}
