.status-messages-nav-item {
    &__dropdown-divider {
        margin: 0.5rem 0;
    }

    &__dropdown-menu {
        min-width: 20rem;
        max-width: 20rem;
        user-select: text;
        box-sizing: content-box;
    }
    &__dropdown-menu-content {
        max-height: 50vh;
        overflow-y: auto;
        padding: 1rem;
        padding-bottom: 0;
    }
    &__entry {
        padding-bottom: 1rem;
        word-wrap: break-word;
        white-space: pre-line;
        &-link {
            margin-bottom: 0;
        }
        &-icon {
            margin-right: 0.375rem;
            &--off {
                color: var(--border-color);
            }
        }
        &-card {
            &--active {
                margin-top: 0.25rem;
                padding: 0 0.75rem;
                margin-left: 0.375rem;
                margin-bottom: 0.625rem;
            }
            &--inactive {
                margin-top: 0.75rem;
                padding: 0.5rem 0.75rem;
                background-color: var(--color-bg-2);
            }
        }
        &-sync {
            margin-bottom: 0.75rem;
        }
        &-message {
            padding: 0;
            margin-bottom: 0.25rem;
            &--error {
                color: $danger;
            }
            &--warning {
                color: #523704;
            }
        }
        &--border-error {
            border-left: 2px solid $danger;
        }
        &--border-success {
            border-left: 2px solid $success;
        }
        &--border-warning {
            border-left: 2px solid $warning;
        }
        &--border-progress {
            border-left: 2px solid $primary;
        }
    }
}
