@import './RepoRevisionSidebarSymbols';

.repo-revision-sidebar {
    &__tabpanels {
        margin-top: 0.25rem;
        height: calc(100% - 2.25rem);
    }

    &__close-icon {
        color: var(--link-color);
    }

    &__toggle {
        display: flex;
        justify-content: center;
        left: 0;
        width: 2rem;
        height: 2rem;
        z-index: 1;
        isolation: isolate;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        color: var(--icon-color);
        background-color: var(--body-bg);

        &:hover {
            background-color: var(--color-bg-3);
        }
    }
}
