.theme-light {
    --input-bg: var(--white);
    --input-disabled-bg: var(--gray-04);
    --input-border-color: var(--gray-04);
    --input-color: var(--gray-09);
    --input-placeholder-color: var(--gray-07);
    --input-group-addon-color: var(--gray-08);
    --input-group-addon-bg: var(--gray-03);
    --input-group-addon-border-color: var(--gray-03);
    --input-focus-border-color: var(--border-active-color);
    --input-focus-box-shadow: var(--focus-box-shadow);

    // Checkbox margins
    --form-check-input-margin-y: 0.3rem;
}

.theme-dark {
    --input-bg: var(--gray-10);
    --input-disabled-bg: var(--gray-08);
    --input-border-color: var(--gray-08);
    --input-color: var(--gray-04);
    --input-placeholder-color: var(--gray-05);
    --input-group-addon-color: var(--gray-01);
    --input-group-addon-bg: var(--gray-08);
    --input-group-addon-border-color: var(--gray-08);
    --input-focus-border-color: var(--border-active-color);
    --input-focus-box-shadow: var(--focus-box-shadow);

    // Checkbox margins
    --form-check-input-margin-y: 0.3rem;
}

// Prevent Firefox's default red outline for inputs
:not(output):-moz-ui-invalid:not(:focus) {
    box-shadow: none;
}
:not(output):-moz-ui-invalid:-moz-focusring:not(:focus) {
    box-shadow: none;
}

// Add an additional feedback class to add context to form controls without requiring a valid state
.field-message {
    @extend .valid-feedback;
    display: block;
    color: var(--text-muted);
}

.was-validated :valid.form-control,
.was-validated :invalid.form-control,
.is-valid.form-control,
.is-invalid.form-control,
.custom-select {
    // Adjust icon padding
    background-position: right 0.75rem center;

    &-sm {
        background-position: right 0.5rem center;
    }
}

// Input feedback messages
.valid-feedback {
    color: var(--text-muted);
}
.invalid-feedback {
    color: var(--danger);
}
.form-check-input {
    ~ .field-message,
    ~ .valid-feedback,
    ~ .invalid-feedback {
        // Adjust spacing for radio/checkboxes
        margin-top: 0;
    }
}

// Update text color to better indicate disabled fields
.form-control,
.custom-select {
    &:disabled {
        color: var(--text-disabled);

        &::placeholder {
            color: var(--text-disabled);
        }
    }
}

// Valid form inputs and selects
.was-validated .form-control:valid,
.was-validated .custom-select:valid,
.form-control.is-valid,
.custom-select.is-valid {
    border-color: var(--success);

    &:focus {
        @at-root #{selector-append('.theme-light', &)} {
            box-shadow: 0 0 0 2px var(--success-2);
        }
        @at-root #{selector-append('.theme-dark', &)} {
            box-shadow: 0 0 0 2px var(--success-3);
        }
    }
}

// Invalid form inputs and selects
.was-validated .form-control:invalid,
.was-validated .custom-select:invalid,
.form-control.is-invalid,
.custom-select.is-invalid {
    border-color: var(--danger);

    &:focus {
        @at-root #{selector-append('.theme-light', &)} {
            box-shadow: 0 0 0 2px var(--danger-2);
        }
        @at-root #{selector-append('.theme-dark', &)} {
            box-shadow: 0 0 0 2px var(--danger-3);
        }
    }
}

// Valid Radio/checkbox labels
.was-validated .form-check-input:valid,
.form-check-input.is-valid {
    ~ .form-check-label {
        color: var(--success);
    }
}

// Invalid Radio/checkbox labels
.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
    ~ .form-check-label {
        color: var(--danger);
    }
}

// Remove feedback icon for <select> and <textarea>
select.form-control,
textarea.form-control:not(.with-invalid-icon) {
    background-image: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    // To improve visual styles for autofill state of all inputs
    // we have to set box-shadow we huge size to override native styles

    // See https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
    box-shadow: 0 0 0 1000px var(--secondary) inset !important;
}
