.saved-search-list-page {
    padding: 0;

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        font-weight: bold;

        &:not(:last-child) {
            padding-bottom: 1rem;
        }
        + .saved-search-list-page__row {
            padding-top: 1rem;
        }

        &--icon {
            margin-right: 0.5rem;
        }
    }
}
